"use client";

import { ShortcutKbd } from "@open/hoose/ui";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@open/hoose/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@open/hoose/ui/dialog";
import { Separator } from "@open/hoose/ui/separator";
import { Command as CommandIcon } from "@phosphor-icons/react";
import { Shortcut } from "enums/shortcut.enum";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { useHotkey } from "hooks/useHotkey";
import { cn } from "lib/utils";
import {
  ComponentRef,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCmdkPages } from "./useCmdkPages";

export type CmdkItem<T extends string = string> = {
  value: T;
  label: string | ReactNode;
  keywords?: string[];
  description?: string | ReactNode;
  onSelect?: (value: T) => void;
  isSelected?: boolean;
  leftElement?: ReactElement;
  /**
   * @example
   * [["g", "s"]] becomes <kbd>g</kbd> then <kbd>s</kbd>
   * [["command+b"], ["["]] becomes <kbd>command+b</kbd> or <kbd>[</kbd>
   */
  shortcuts?: (typeof Shortcut)[keyof typeof Shortcut][][];
};

export type CmdkGroup<T extends string = string> = {
  title?: string;
  items: Array<CmdkItem<T>>;
};

export type CmdkPage = {
  title: string;
  groups: CmdkGroup[];
};

export function Cmdk() {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const listRef = useRef<ComponentRef<typeof CommandList> | null>(null);

  useHotkey(
    [Shortcut["command+k"]],
    () => setOpen((prev) => !prev),
    {
      description: "Open Cmdk",
      icon: <CommandIcon />,
    },
    [setOpen],
  );

  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const pages = useCmdkPages();
  const selectedPage = pages[selectedPageIndex] || {
    title: "Oops",
    groups: [],
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: `input` is an intentional dep
  useEffect(() => {
    listRef.current?.scrollTo({ top: 0, behavior: "instant" });
  }, [input]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        aria-describedby="cmdk"
        className={cn("p-0 mt-[15vh] rounded-2xl bg-transparent max-w-2xl")}
        overlayProps={{
          className: cn(
            "items-start backdrop-blur-none bg-accent-foreground/10",
          ),
        }}
      >
        <DialogHeader className="sr-only">
          <DialogTitle>Cmdk</DialogTitle>
          <DialogDescription>
            Search and navigate through the app.
          </DialogDescription>
        </DialogHeader>
        <Command className="rounded-2xl bg-background w-full">
          <CommandInput
            placeholder="Type a command or search..."
            className="h-auto text-base px-2 pt-4"
            value={input}
            onValueChange={setInput}
            withSearchIcon={false}
          />
          <CommandList ref={listRef}>
            <CommandEmpty>¯\_(ツ)_/¯</CommandEmpty>
            <AnimatePresence mode="wait">
              <motion.div
                key={`group-index-${selectedPageIndex}`}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 10 }}
              >
                {selectedPage.groups.map((group, i) => (
                  <CommandGroup key={i} heading={group.title}>
                    {i !== 0 && (
                      <div key={`separator-${i}`} className="flex-center px-2">
                        <Separator className="opacity-40" />
                      </div>
                    )}
                    {group.items.map((item) => (
                      <CommandItem
                        key={item.value}
                        value={item.value}
                        keywords={[
                          item.value,
                          ...(typeof item.label === "string" ? item.label : []),
                          ...(group.title ? [group.title] : []),
                          ...(item.keywords ?? []),
                        ]}
                        onSelect={(_) => {
                          setOpen(false);

                          item.onSelect?.(item.value);

                          // Reset input after closing
                          setTimeout(() => {
                            setInput("");
                          }, 500);
                        }}
                      >
                        <div className="flex flex-col flex-1 gap-2">
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-2 [&_svg]:size-4">
                              {item.leftElement && <>{item.leftElement}</>}
                              {typeof item.label === "string" ? (
                                <p>{item.label}</p>
                              ) : (
                                <>{item.label}</>
                              )}
                            </div>
                            {item.shortcuts?.map((shortcutSequence, i) => (
                              <Fragment
                                key={`${shortcutSequence.join("then")}-${i}`}
                              >
                                {i !== 0 && <span>or</span>}
                                {shortcutSequence.map((shortcut, j) => (
                                  <Fragment key={`${shortcutSequence}-${j}`}>
                                    {j !== 0 && <span>then</span>}
                                    <ShortcutKbd
                                      className={cn(
                                        // Only add the margin in the
                                        i === 0 && "ml-auto",
                                      )}
                                    >
                                      {shortcut}
                                    </ShortcutKbd>
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                          </div>
                          {item.description && (
                            <p className="text-muted-foreground text-sm whitespace-pre-wrap break-words">
                              {item.description}
                            </p>
                          )}
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ))}
              </motion.div>
            </AnimatePresence>
          </CommandList>
        </Command>
      </DialogContent>
    </Dialog>
  );
}
