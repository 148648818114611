"use client";
import { CmdkPage } from "./Cmdk";
import { useCmdkGroup__Navigation } from "./useCmdkGroup__Navigation";
import { useCmdkGroup__Shortcuts } from "./useCmdkGroup__Shortcuts";

export function useCmdkPages__Home(): CmdkPage {
  const currentPageCommandsGroup = useCmdkGroup__Shortcuts();
  const navigationGroup = useCmdkGroup__Navigation();

  return {
    title: "Home",
    groups: [
      // flatmap into one group
      {
        items: [...currentPageCommandsGroup.items, ...navigationGroup.items],
      },
    ],
  };
}
