"use client";

import { cn } from "@open/hoose/utils/cn";
import { AnimatePresence } from "framer-motion";
import { ReactNode } from "react";
import { MotionDiv } from "./MotionDiv";
import { Spinner } from "./Spinner";

interface SettingsPageContainerProps {
  className?: string;
  contentClassName?: string;
  noTrailingSeparator?: boolean;
  /**
   * If passed as a string, it will be rendered as a heading
   */
  heading: string | NonNullable<ReactNode>;
  actions?: ReactNode;
  isLoading?: boolean;
  children: ReactNode;
}

export function PageContainer({
  className,
  contentClassName,
  noTrailingSeparator = false,
  heading,
  actions,
  isLoading = false,
  children,
}: SettingsPageContainerProps) {
  return (
    <div className="size-full overflow-auto">
      <div
        className={cn(
          "relative size-full max-w-screen-md mx-auto py-8 px-8 space-y-8",
          className,
        )}
      >
        <MotionDiv
          fadeIn="up"
          className="flex items-end justify-between gap-4 shrink-0"
        >
          {typeof heading === "string" ? (
            <h1 className="text-2xl font-bold tracking-tight shrink-0">
              {heading}
            </h1>
          ) : (
            heading
          )}
          {actions && (
            <div className="flex-center gap-2 flex-wrap">{actions}</div>
          )}
        </MotionDiv>

        <AnimatePresence mode="wait">
          {isLoading ? (
            <MotionDiv
              key="loader"
              fadeIn="down"
              className={cn(
                "w-full flex-center",
                "pt-[35dvh]", // This kinda makes it in the center of the screen
              )}
            >
              <Spinner />
            </MotionDiv>
          ) : (
            <MotionDiv
              key="content"
              fadeIn="down"
              className={cn("space-y-6", contentClassName)}
            >
              {children}
            </MotionDiv>
          )}
        </AnimatePresence>

        {!noTrailingSeparator && <div role="separator" className="h-px" />}
      </div>
    </div>
  );
}
