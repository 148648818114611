import(/* webpackMode: "eager", webpackExports: ["AppSidebar"] */ "/app/apps/dashboard/app/_components/AppSidebar/AppSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cmdk"] */ "/app/apps/dashboard/app/_components/Cmdk/Cmdk.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HandleUrlCopy"] */ "/app/apps/dashboard/app/_components/HandleUrlCopy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectOrganization"] */ "/app/apps/dashboard/app/_components/SelectOrganization.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsProvider"] */ "/app/apps/dashboard/app/_data-providers/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrgProvider"] */ "/app/apps/dashboard/app/_data-providers/OrgDataProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/app/apps/dashboard/app/_data-providers/SocketProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__r_4r2zludr5mtkeya6a5qgdkvapq/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__r_4r2zludr5mtkeya6a5qgdkvapq/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.84.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider","SidebarInset"] */ "/app/packages/hoose/dist-separated/ui/sidebar.js");
