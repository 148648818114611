"use client";
import { createSafeContext } from "@open/hoose/utils/create-safe-context";
import { Dto } from "@open/sdk";
import { useApi } from "app/_data-providers/ApiProvider.useApi";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import { useOrgData } from "./OrgDataProvider";
import { useSocketEvent } from "./SocketProvider";

type NotificationDto = Dto["NotificationDto"];

const [Provider, useNotifications] = createSafeContext<{
  notifications: NotificationDto[];
  markAsRead: (notificationIds: number[]) => void;
  hasUnreads: boolean;
  unreadIds: number[];
}>("");

async function testData(): Promise<NotificationDto[]> {
  return [
    {
      affected_entity_id: "12345",
      id: 1,
      entity_id: "abcde",
      notification_type: "created",
      message: "Your profile has been updated.",
      read_at: null,
      created_at: "2024-10-01T10:00:00Z",
      target_user_id: 101,
      affected_entity_type: "user",
      organization_id: "org_001",
    },
    {
      affected_entity_id: null,
      id: 2,
      entity_id: "fghij",
      notification_type: "deleted",
      message: "Your subscription will expire soon.",
      read_at: null,
      created_at: "2024-10-02T12:30:00Z",
      target_user_id: 102,
      affected_entity_type: "chat_session",
      organization_id: "org_002",
    },
    {
      affected_entity_id: "67890",
      id: 3,
      entity_id: "klmno",
      notification_type: "other",
      message: null,
      read_at: "2024-10-02T14:00:00Z",
      created_at: "2024-10-03T09:15:00Z",
      target_user_id: 103,
      affected_entity_type: "user",
      organization_id: null,
    },
    {
      affected_entity_id: "54321",
      id: 4,
      entity_id: "pqrst",
      notification_type: "other",
      message: "Your payment was successful.",
      read_at: null,
      created_at: "2024-10-04T16:45:00Z",
      target_user_id: 104,
      affected_entity_type: "other",
      organization_id: "org_003",
    },
  ];
}

function NotificationsProvider({
  children,
  fallbackData,
}: { children: React.ReactNode; fallbackData?: NotificationDto[] }) {
  const { apiClient } = useApi();
  const [notifications, setNotifications] = useState<NotificationDto[]>(
    fallbackData ?? [],
  );
  const unreadIds = useMemo(() => {
    return notifications.filter((n) => !n.read_at).map((n) => n.id);
  }, [notifications]);

  async function markAsRead(notificationIds: number[]) {
    if (!notificationIds.length) return;
    const updatedNotifications = notifications.map((n) => {
      if (notificationIds.includes(n.id)) {
        return { ...n, read_at: new Date().toISOString() };
      }
      return n;
    });
    setNotifications(updatedNotifications);
    await apiClient.PUT("/backend/notifications/mark-read", {
      // @ts-ignore
      body: { notificationIds },
    });
  }
  async function markAllAsRead() {
    await markAsRead(unreadIds);
    const notificationsPrelaude = await apiClient.GET(
      "/backend/notifications/unread",
    );
    setNotifications(notificationsPrelaude.data ?? []);
  }

  const hasUnreads = useMemo(
    () => notifications.some((n) => !n.read_at),
    [notifications],
  );

  // recieve notifications from the server, not the whole table but the new ones
  // so we want to merge them.
  // handle when there is alrady one with the same id
  useSocketEvent("notifications", async (data: NotificationDto[]) => {
    const newNotifications = data.filter(
      (n) => !notifications.some((nn) => nn.id === n.id),
    );
    setNotifications([...newNotifications, ...notifications]);
  });

  return (
    <Provider value={{ notifications, markAsRead, hasUnreads, unreadIds }}>
      {children}
    </Provider>
  );
}

const criteria = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("all"),
  }),
  z.object({
    type: z.literal("entity"),
    entity: z.object({
      id: z.number(),
      type: z.string(),
    }),
  }),
]);

function useSubscribeToNotificationCriteria(
  c: z.infer<typeof criteria>,
  callback: (matches: NotificationDto[]) => void,
) {
  useSocketEvent("notifications", async (data: NotificationDto[]) => {
    switch (c.type) {
      case "all":
        callback(data);
        break;
      case "entity":
        break;
    }
  });
}

export {
  NotificationsProvider,
  useNotifications,
  type NotificationDto,
  useSubscribeToNotificationCriteria,
};
