"use client";
import { Dto } from "@open/sdk";
import { useApi } from "app/_data-providers/ApiProvider.useApi";

import { useSession } from "next-auth/react";
import useSWR from "swr";

export type CopilotDto = Dto["ChatbotDto"];

export function useFetchUserOrgs() {
  const session = useSession();
  const { apiClient } = useApi();

  return useSWR(
    ["/backend/chatbot-users", session.data?.user?.email],
    async () => {
      if (!session.data?.user?.email) return null;
      const resp = await apiClient.GET("/backend/chatbot-users");
      return resp.data;
    },
  );
}
