import { useShortcutsRegistry } from "app/_data-providers/ClientifiedHotkeysProvider";
import { Shortcut, hotkeyToString } from "enums/shortcut.enum";
import { ReactElement, ReactNode, useMemo } from "react";
import { useHotkeysContext } from "react-hotkeys-hook";
import { Hotkey, HotkeyCallback } from "react-hotkeys-hook/dist/types";
import { CmdkGroup, CmdkItem } from "./Cmdk";

export function useCmdkGroup__Shortcuts(): CmdkGroup {
  const { hotkeys } = useHotkeysContext();
  const { registry } = useShortcutsRegistry();

  const items = useMemo(() => {
    type Description = string;
    const groupedHotKeys: Record<Description, Hotkey[]> = {};

    hotkeys.forEach((h) => {
      if (!h.description) return;
      const shortcutKey = hotkeyToString(h);
      if (!shortcutKey) return;

      // Ignore showing the "command+k" shortcut in the Cmdk... duh
      if (shortcutKey === "command+k") return;

      const shortcutValue = Shortcut[shortcutKey];
      const registryItem = registry.get(shortcutValue);
      if (!registryItem) return;

      groupedHotKeys[h.description] = [
        ...(groupedHotKeys[h.description] || []),
        h,
      ];
    });

    return Object.entries(groupedHotKeys).flatMap(([description, hotkeys]) => {
      const shortcutKeys = hotkeys
        .map((h) => hotkeyToString(h))
        .filter((h) => h !== null);
      if (!shortcutKeys.length) return [];

      const shortcutValues = shortcutKeys.map((k) => Shortcut[k]);

      // Pick any shortcut value to be the registry key... shortcut values doing the same thing should have the same callback and icon anyway
      const registryKey = shortcutValues[0];
      if (!registryKey) return [];

      return {
        value: description,
        label: description,
        keywords: ["shortcuts", "hotkeys", "keyboard", "shortcut", "command"],
        onSelect: () =>
          // get the callback at runtime from the non-reactive registry
          registry
            .get(registryKey)
            ?.callback?.(
              new KeyboardEvent("keydown"),
              {},
            ),
        // There is an `or` relationship between each child array... so we split each shortcut value into its own array
        shortcuts: shortcutValues.map((v) => [v]),
        leftElement: registry.get(registryKey)?.icon,
      } satisfies CmdkItem;
    });
  }, [hotkeys]);

  return {
    title: "Shortcuts",
    items,
  };
}
