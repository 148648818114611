"use client";

import { Sidebar } from "@open/hoose/ui/sidebar";
import { AppSidebar__Content } from "./AppSidebar__Content";
import { AppSidebar__Footer } from "./AppSidebar__Footer";
import { AppSidebar__Header } from "./AppSidebar__Header";

export function AppSidebar() {
  return (
    <Sidebar
      collapsible="icon"
      className="border-none"
      innerClassName="bg-transparent"
    >
      <AppSidebar__Header />
      <AppSidebar__Content />
      <AppSidebar__Footer />
      {/* <AppSidebar__Rail /> */}
    </Sidebar>
  );
}
