import { Avatar, AvatarFallback, AvatarImage } from "@open/hoose/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@open/hoose/ui/dropdown-menu";
import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@open/hoose/ui/sidebar";
import { Plus } from "@phosphor-icons/react";
import { useSocket } from "app/_data-providers/SocketProvider";
import { useUserData } from "app/_data-providers/UserDataProvider";
import { cn } from "lib/utils";
import {
  ChevronsUpDown,
  CircleHelp,
  CreditCard,
  LogOut,
  MailPlus,
} from "lucide-react";
import { signOut } from "next-auth/react";
export function AppSidebar__Footer() {
  const { state: sidebarState } = useSidebar();
  const user = useUserData();
  const initials = user.name
    .split(" ")
    .map((n) => n[0])
    .join("");
  const {
    state: { state },
  } = useSocket();
  const isOnline = state === "connected";

  return (
    <SidebarFooter>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton size="lg" variant="outline">
                <Avatar
                  className={cn(
                    "text-xs h-8 w-8 rounded-full overflow-visible relative transition",
                    "shadow-inner [--tw-shadow-colored:_inset_0_0_0_2px_var(--tw-shadow-color)]",
                    sidebarState === "expanded"
                      ? "shadow-transparent"
                      : isOnline
                        ? "shadow-emerald-500"
                        : "shadow-gray-500",
                  )}
                >
                  <AvatarImage
                    src={user.avatar_url ?? undefined}
                    alt={user.name}
                    className="rounded-full overflow-hidden"
                  />
                  <AvatarFallback className="rounded-full overflow-hidden">
                    {initials}
                  </AvatarFallback>
                  <span
                    className={cn(
                      "size-3 rounded-full bottom-0 right-0 absolute transition",
                      sidebarState === "collapsed"
                        ? "bg-transparent"
                        : isOnline
                          ? "bg-emerald-500"
                          : "bg-gray-600",
                    )}
                  />
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.name}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
                <ChevronsUpDown className="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56"
              side="top"
              align="start"
              sideOffset={4}
            >
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={() =>
                    window.open("mailto:mo+inapp@open.cx", "_blank")
                  }
                >
                  <MailPlus className="size-4" />
                  Email Support
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() =>
                    window.open("https://docs.opencopilot.so", "_blank")
                  }
                >
                  <CircleHelp className="size-4" />
                  Documentation
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() =>
                    window.open(
                      "https://billing.stripe.com/p/login/8wM7sC5ToggP03CdQQ",
                      "_blank",
                    )
                  }
                >
                  <CreditCard className="size-4" />
                  Billing
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={() => window.open("/create", "_self")}
                >
                  <Plus className="size-4" />
                  Create Organization
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onSelect={(ev) => {
                  ev?.preventDefault();
                  signOut({ redirect: true });
                }}
                className="text-destructive hover:bg-destructive/90 hover:text-destructive-foreground"
              >
                <LogOut className="size-4" />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
  );
}
