"use client";
import { ShortcutKbd } from "@open/hoose/ui";
import { Avatar, AvatarFallback } from "@open/hoose/ui/avatar";
import { Combobox, ComboboxGroup } from "@open/hoose/ui/combo-box";
import {
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@open/hoose/ui/sidebar";
import { doSomeMagicWithKeys } from "@open/hoose/utils/hotkeys";
import { Plus, SidebarSimple, UserSwitch } from "@phosphor-icons/react";
import { useFetchUserOrgs } from "app/_data-hooks/useFetchUserOrgs";
import { useOrgData } from "app/_data-providers/OrgDataProvider";
import { useLinkHirarcy } from "app/_hooks/useLinkHirarcy";
import { ClientOnly } from "components/headless/ClientOnly";
import { Shortcut } from "enums/shortcut.enum";
import { motion } from "framer-motion";
import { useAsyncFn } from "hooks/useAsyncFn";
import { useHotkey } from "hooks/useHotkey";
import { useRouter } from "lib/router-events";
import { cn } from "lib/utils";
import { ChevronsUpDown, Cog } from "lucide-react";
import { signIn } from "next-auth/react";
import { usePathname } from "next/navigation";
import { ComponentProps, Fragment, useEffect, useState } from "react";

const animation: ComponentProps<typeof motion.div> = {
  initial: { opacity: 0, y: -8 },
  animate: { opacity: 1, y: 0 },
};

function getInitials(name: string, max = 2) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .slice(0, max);
}

const orgSwitcherShortcut = Shortcut["command+o"];

function OrgSwitcherShortcut() {
  return (
    <motion.div {...animation} className="flex items-center gap-1">
      <ShortcutKbd>{orgSwitcherShortcut}</ShortcutKbd>
    </motion.div>
  );
}

export const AppSidebar__Header = () => {
  const { state, toggleSidebar } = useSidebar();
  const { data: orgs, isLoading } = useFetchUserOrgs();
  const router = useRouter();
  const links = useLinkHirarcy();
  const { id } = useOrgData();
  const pathname = usePathname();
  const currentOrg = orgs?.find((org) => org.id === id);
  const initials = currentOrg ? getInitials(currentOrg.name) : null;
  const [openOrgSwitcher, setOpenOrgSwitcher] = useState(false);

  const [_orgChangeState, handleOrgChange] = useAsyncFn(
    async (orgId: string) => {
      await signIn("switch-org", {
        orgId,
        redirect: true,
        callbackUrl: "/",
      });
    },
    [id, pathname, router],
  );

  useHotkey(
    [orgSwitcherShortcut],
    () => {
      setOpenOrgSwitcher(true);
    },
    {
      description: "Switch organization",
      icon: <UserSwitch />,
    },
    [setOpenOrgSwitcher],
  );

  useHotkey(
    [Shortcut["["]],
    () => {
      toggleSidebar();
    },
    {
      description: "Toggle sidebar",
      icon: <SidebarSimple />,
    },
    [toggleSidebar],
  );

  useEffect(() => {
    router.prefetch(links.org.create);
  }, []);

  return (
    <SidebarHeader>
      <SidebarMenu>
        {/* <SidebarMenuItem>
          <AppSidebar__Trigger />
        </SidebarMenuItem> */}
        <SidebarMenuItem>
          <Combobox
            align="start"
            controlled={{
              open: {
                open: openOrgSwitcher,
                setOpen: setOpenOrgSwitcher,
              },
            }}
            Trigger={
              <SidebarMenuButton
                size="lg"
                variant="outline"
                className={cn(
                  state === "collapsed" && "mt-2",
                  isLoading || (!initials && "animate-pulse"),
                )}
              >
                <div className="shrink-0 flex aspect-square size-8 items-center justify-center rounded-full bg-primary text-primary-foreground">
                  {initials && (
                    <motion.span {...animation}>{initials}</motion.span>
                  )}
                </div>

                <div className="grid flex-1 text-left text-sm leading-tight">
                  {currentOrg?.name && (
                    <motion.span
                      {...animation}
                      className="truncate font-semibold"
                    >
                      {currentOrg?.name}
                    </motion.span>
                  )}
                </div>
                {/* <ClientOnly>
                  <OrgSwitcherShortcut />
                </ClientOnly> */}
                <ChevronsUpDown />
              </SidebarMenuButton>
            }
            groups={[
              {
                items: (orgs || []).map(
                  (o) =>
                    ({
                      value: o.id,
                      label: o.name,
                      onSelect: () => handleOrgChange(o.id),
                      isSelected: o.id === currentOrg?.id,
                      leftElement: (
                        <Avatar size="small" bg="primary">
                          <AvatarFallback>{getInitials(o.name)}</AvatarFallback>
                        </Avatar>
                      ),
                    }) satisfies ComboboxGroup["items"][number],
                ),
              },
              {
                forceMount: true,
                items: [
                  {
                    value: "create-new-org",
                    label: "Create organization",
                    leftElement: <Plus />,
                    onSelect: () => router.push(links.org.create),
                  },
                ],
              },

            ]}
          />
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>
  );
};

AppSidebar__Header.displayName = "AppSidebar__Header";
