"use client";
import { ArrowRight } from "@phosphor-icons/react";
import { useLinkHirarcy } from "app/_hooks/useLinkHirarcy";
import { useRouter } from "lib/router-events";
import { useCallback, useMemo } from "react";
import { CmdkGroup, CmdkItem } from "./Cmdk";

type Leaf = { title: string; value: string };
const LEAF_KEYS: Array<keyof Leaf> = ["title", "value"];

function isLeaf(v: unknown): v is Leaf {
  if (typeof v !== "object" || v === null) return false;
  return LEAF_KEYS.every((key) => key in v);
}

type TransformedLinks<T = ReturnType<typeof useLinkHirarcy>["org"]> = {
  [K in keyof T]: T[K] extends (...args: (infer _)[]) => string // Check if it's a function
    ? null // Ignore functions
    : T[K] extends string // Check if it's a string
      ? Leaf | null // Transform strings
      : TransformedLinks<T[K]> | null; // Recurse for nested objects
};

export function useCmdkGroup__Navigation(): CmdkGroup {
  const links = useLinkHirarcy().org;
  const router = useRouter();

  const handleNavigate = useCallback<NonNullable<CmdkItem["onSelect"]>>(
    (value) => {
      router.push(value);
    },
    [router],
  );

  const leafToItem = useCallback(
    (leaf: Leaf): CmdkItem => {
      return {
        value: leaf.title,
        label: (
          <span>
            Go to <b>{leaf.title}</b>
          </span>
        ),
        onSelect: () => handleNavigate(leaf.value),
        leftElement: <ArrowRight />,
      } satisfies CmdkItem;
    },
    [handleNavigate],
  );

  const transformedLinks: TransformedLinks = useMemo(
    () => ({
      settings: {
        index: {
          title: "Settings / General",

          value: links.settings.index,
        },
        accessToken: {
          title: "Settings / Access Tokens",
          value: links.settings.accessToken,
        },
        aiAutopilot: {
          title: "Settings / AI Autopilot",
          value: links.settings.aiAutopilot,
        },
        aiEvals: {
          title: "Settings / AI Evaluations",
          value: links.settings.aiEvals,
        },
        appearance: {
          title: "Settings / Appearance",
          value: links.settings.appearance,
        },
        context: {
          title: "Settings / AI Profile",
          value: links.settings.context,
        },
        domains: {
          title: "Settings / Domains",
          value: links.settings.domains,
        },
        integrations: {
          title: "Settings / Integrations",
          value: links.settings.integrations,
        },
        officeHours: {
          title: "Settings / Office Hours",
          value: links.settings.officeHours,
        },
        groups: {
          title: "Settings / Teams",
          value: links.settings.groups,
        },
        teammates: {
          title: "Settings / Teammates",
          value: links.settings.teammates,
        },
        handoff: {
          title: "Settings / Handoff",
          value: links.settings.handoff,
        },
        jira: {
          title: "Settings / Jira",
          value: links.settings.jira,
        },
        knowledgebase: {
          title: "Settings / Knowledgebase",
          value: links.settings.knowledgebase,
        },
        phoneNumbers: {
          title: "Settings / Phone Numbers",
          value: links.settings.phoneNumbers,
        },
        security: {
          title: "Settings / Security",
          value: links.settings.security,
        },
        tags: {
          title: "Settings / Organization Tags",
          value: links.settings.tags,
        },
        webhooks: {
          title: "Settings / Webhooks",
          value: links.settings.webhooks,
        },
        agentAssignment: {
          title: "Settings / Human Agent Assignment",
          value: links.settings.agentAssignment,
        },
        ticketing: {
          title: "Settings / Ticketing",
          value: links.settings.ticketing,
        },
        variables: {
          title: "Settings / Organization Variables",
          value: links.settings.variables,
        },
      },

      inbox: {
        index: {
          title: "Inbox",
          value: links.inbox.index,
        },
        session: null,
      },

      reports: {
        index: {
          title: "Reports / Impact Dashboard",
          value: links.reports.index,
        },
        aiInsights: {
          title: "Reports / AI Recommendations",
          value: links.reports.aiInsights,
        },
        voiceOfCustomer: {
          title: "Reports / Customer Insights",
          value: links.reports.voiceOfCustomer,
        },
        dataExport: {
          title: "Reports / Data Export",
          value: links.reports.dataExport,
        },
        handoffs: {
          title: "Reports / Handoffs",
          value: links.reports.handoffs,
        },
        wrapped2024: null,
      },

      channels: {
        index: {
          title: "Channels",
          value: links.channels.index,
        },
        configure: {
          web: {
            title: "AI Agents / Web",
            value: links.channels.configure.web,
          },
          email: {
            title: "AI Agents / Email",
            value: links.channels.configure.email,
          },
        },
        slack: {
          title: "AI Agents / Slack",
          value: links.channels.slack,
        },
        sms: {
          index: {
            title: "AI Agents / SMS",
            value: links.channels.sms.index,
          },
        },
        whatsapp: {
          index: {
            title: "AI Agents / WhatsApp",
            value: links.channels.whatsapp.index,
          },
        },
        phone: {
          index: null,
          agents: {
            title: "AI Agents / Phone",
            value: links.channels.phone.agents,
          },
          create: {
            title: "AI Agents / Phone / Create",
            value: links.channels.phone.create,
          },
          createWithTemplate: null,
          agent: null,
        },
      },
      email: null,
      sequencing: {
        index: {
          title: "Sequencing",
          value: links.sequencing.index,
        },
        sequence: null,
      },
      workflows: {
        index: {
          title: "Workflows",
          value: links.workflows.index,
        },
        create: {
          title: "Workflows / Create",
          value: links.workflows.create,
        },
        workflow: null,
      },

      contacts: {
        index: {
          title: "Contacts",
          value: links.contacts.index,
        },
        contact: null,
      },

      helpCenter: {
        index: {
          title: "Help Centers",
          value: links.helpCenter.index,
        },
        create: {
          title: "Help Centers / Create",
          value: links.helpCenter.create,
        },
        edit: null,
        helpCenter: null,
        categories: null,
      },

      actions: {
        index: {
          title: "Actions",
          value: links.actions.index,
        },
        newAction: {
          title: "Actions / Create",
          value: links.actions.newAction,
        },
        action: null,
      },

      knowledge: {
        index: {
          title: "AI Knowledge",
          value: links.knowledge.index,
        },
      },

      training: {
        index: {
          title: "AI Training",
          value: links.knowledge.index,
        },
      },
      create: {
        title: "Create Organization",
        value: links.create,
      },
    }),
    [links],
  );

  const items: CmdkItem[] = useMemo(() => {
    const list: CmdkItem[] = [];
    function toItems(v: TransformedLinks | Leaf | null) {
      if (!v) return;

      if (isLeaf(v)) {
        list.push(leafToItem(v));
        return;
      }

      for (const key in v) {
        const value = v[key as keyof typeof v];
        toItems(value as TransformedLinks | Leaf | null); // Recursively process nested objects
      }
    }
    toItems(transformedLinks);
    return list;
  }, [transformedLinks, leafToItem]);

  return {
    title: "Navigation",
    items,
  };
}
